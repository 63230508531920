// @ts-check
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Menu } from './Menu.js';

export function InitializeReact(){
    const telaniAdminContainer = document.getElementById('telaniAdmin');
    if (telaniAdminContainer !== null){
        console.error("this page should not have the admin container.");
    }
    else{
        console.log("Not initalizing react app, just rendering menu.");
        const menuContainer = document.getElementById('react-menu');
        if (menuContainer !== null){
            const root = createRoot(menuContainer);
            root.render(<React.StrictMode>
                <Menu />
                </React.StrictMode>);
        }
    }
}