// @ts-check
import { InitializeLightbox } from './lightbox.js';
import { InitializeReact } from './react-entry-php.js';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faBars, faPencil, faGavel, faAlignJustify, faExclamation, faClock, faRocket } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import '../css-src-php/main-php.css'

library.add(
    faAngleDown,
    faBars,
    faPencil, 
    faGavel, 
    faAlignJustify, 
    faExclamation, 
    faClock, 
    faRocket,
    faLinkedinIn,
    faFacebookF
  );

dom.watch();

var _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */

_paq.push(['addDownloadExtensions', "appinstaller"]);
_paq.push(['setCustomDimension', 1, getColorScheme()]);
_paq.push(['enableLinkTracking']);
_paq.push(['enableHeartBeatTimer']);
_paq.push(['requireCookieConsent']);
_paq.push(['enableJSErrorTracking']);
_paq.push(['setTrackerUrl', 'https://status.telani.net/status.php?test=fail']);
_paq.push(['setSiteId', '11']);
_paq.push(['trackPageView']);

/**
 * @returns {string}
 */
function getColorScheme(){
    if ( window.matchMedia("(prefers-color-scheme:dark)").matches) {
        return "dark";
    } else {
        return "light";
    }
}

function InitializeHelpers(){
  let datatag = document.getElementById("download-links");
  if (datatag !== null){
      let pruefbuch_button = document.getElementById("download_link_pruefbuch");
      let matrix_button = document.getElementById("download_link_matrix");
      if (pruefbuch_button !== null){
          pruefbuch_button.setAttribute("href", datatag.dataset.downloadLinkPruefbuch);
      }
      if (matrix_button !== null){
          matrix_button.setAttribute("href", datatag.dataset.downloadLinkMatrix)   
      }
  }
}

window.addEventListener("DOMContentLoaded", function (event) {
    InitializeReact();
    InitializeHelpers();
    InitializeLightbox();
});
